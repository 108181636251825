import React from 'react'
import { v4 } from 'uuid'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import { MdCheck } from 'react-icons/md'
import {
  GiCaptainHatProfile,
  GiCarambola,
  GiBoatFishing,
  GiLifeBuoy,
  GiScubaTanks,
  GiSwimfins,
} from 'react-icons/gi'

import {
  Stack,
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  Button,
  useTheme,
  Divider,
} from '@chakra-ui/react'

import {
  Article,
  H1,
  H2,
  Section,
  Gallery,
  Image,
  P,
  FeatureList,
  FeatureItem,
  H3,
  FloatingBanner,
} from '../ui'

import Layout from './layout'
import ButtonBook from './buttonBook'

const FeatureRoomItem = ({ title, desc, icon }) => (
  <FeatureItem
    width={{ base: '125px', lg: 'initial' }}
    spacing={1}
    margin={1}
    padding={4}
    boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
    borderRadius="15px"
    icon={icon}
    title={title}
    desc={<P lineHeight="shorter">{desc}</P>}
  />
)

const CommonSpacesItem = ({ text }) => (
  <P
    paddingX={3}
    paddingY={1}
    marginBottom={2}
    backgroundColor="primary.50"
    borderRadius="100px"
    marginRight={2}
    flexShrink="0"
  >
    {text}
  </P>
)

function LayoutRoom({ title, desc, amenities, images, roomType }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const roomIcons = [
    <GiCaptainHatProfile color={theme.colors.primary[400]} size="2rem" />,
    <GiCarambola color={theme.colors.primary[400]} size="2rem" />,
    <GiBoatFishing color={theme.colors.primary[400]} size="2rem" />,
    <GiLifeBuoy color={theme.colors.primary[400]} size="2rem" />,
    <GiScubaTanks color={theme.colors.primary[400]} size="2rem" />,
    <GiSwimfins color={theme.colors.primary[400]} size="2rem" />,
  ]

  return (
    <Layout title={title} variantHeader="solid">
      <Article>
        <Section paddingBottom="0">
          <Gallery images={images} buttonText={t('common:viewPhotos')} />
        </Section>

        <Section>
          <Flex direction={{ base: 'column', lg: 'row' }}>
            <Stack flex={6 / 8} marginRight={{ base: 0, lg: 10 }} spacing={5}>
              <Stack>
                <H1>{title}</H1>
                <P>{desc}</P>
              </Stack>
              <Divider />
              <H3>{t('suites:locationTitle')}</H3>
              <Flex>
                <Box
                  as="iframe"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15132.718803922353!2d-88.3789479!3d18.5207798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ea31b61e2a59f9a!2sMayan%20Secret%20Hotel%20Boutique!5e0!3m2!1sen!2smx!4v1616532623211!5m2!1sen!2smx"
                  width="100%"
                  height={{ base: '300px', lg: '400px' }}
                  frameBorder="0"
                  style={{ border: '0' }}
                  aria-hidden="false"
                />
              </Flex>
            </Stack>

            <Stack
              position={{ md: 'sticky' }}
              top="20px"
              marginTop={{ base: 5, lg: 0 }}
              flex={2 / 8}
              width="100%"
              padding={4}
              borderRadius="15px"
              boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
              spacing={4}
              alignSelf="flex-start"
            >
              <ButtonBook roomType={roomType} width="100%" />
              <H2 fontWeight="bold">{t('suites:amenitiesTitle')}</H2>
              <List>
                {amenities.map((text) => (
                  <ListItem key={v4()} display="flex" alignItems="center">
                    <ListIcon as={MdCheck} size="5rem" color="green.500" />
                    <P>{text}</P>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Flex>
        </Section>
      </Article>
    </Layout>
  )
}

export default LayoutRoom
